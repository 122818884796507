<template>
  <div class="trackers-section site-width">
    <div class="trackers-section__title home-component-title">
      <h2>Welcome to Adease: Where Innovation Meets Insights</h2>
    </div>

    <div
      class="trackers-section__text"
      style="background-image: url('/img/trackers-section-component.png')"
    >
      <div class="trackers-section__text-item backdrop-filter-blur">
        <p>
          We are more than just a data company; we're your partner in harnessing
          the power of advertising intelligence. With over 20 years leading the
          charge in technological innovation, Adease Advertising Intelligence
          has developed sophisticated software to deliver advertising insights
          with unparalleled speed.
        </p>
      </div>
    </div>

    <div class="trackers-section__text2">
      <p>
        Our near real-time data empowers clients to make informed decisions,
        enhancing strategies across various channels. This level of insight
        positions you to navigate market trends confidently, optimize your
        advertising efforts, and secure a competitive advantage.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "TrackersSectionComponent",
};
</script>

<style>
.trackers-section {
  padding: 120px 0;
}

.trackers-section__text {
  position: relative;
  width: 100%;
  height: 900px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
  margin-top: 80px;
}

.trackers-section__text-item {
  color: #fff;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 48px;
  letter-spacing: -0.64px;
  padding: 52px;
  position: absolute;
  bottom: 0;
}

.trackers-section__text2 {
  color: #232429;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 48px;
  letter-spacing: -0.64px;
  margin-top: 70px;
  padding: 0 52px;
}

@media (max-width: 1600px) {
  .trackers-section__text {
    height: 500px;
  }

  .trackers-section {
    padding: 0;
  }

  .trackers-section__text-item {
    font-size: 24px;
    line-height: 35px;
    letter-spacing: -0.32px;
    padding: 44px 52px;
  }

  .trackers-section__text2 {
    font-size: 30px;
    margin-top: 40px;
  }
}
</style>
