<template>
  <AppLayout>
    <FirstComponent text="A View Into Every Dimension of Advertising" />

    <!--counter-->
    <div ref="secondComponent">
      <SecondComponent
        :items="secondItems"
        :isVisible="isVisibleSecondComponent"
      />
    </div>

    <!--Where Innovation Meets Insights-->
    <div
      data-animate-onscroll-offset="500"
      v-animate-onscroll="'animated fadeIn'"
      class="animatedSection"
    >
      <TrackersSectionComponent />
    </div>

    <!--Plan Better-->
    <div
      data-animate-onscroll-offset="500"
      v-animate-onscroll="'animated fadeIn'"
      class="site-width animatedSection"
    >
      <PlanBetterComponent />
    </div>

    <!--Our Solutions-->
    <div
      data-animate-onscroll-offset="500"
      v-animate-onscroll="'animated fadeIn'"
      class="site-width animatedSection"
    >
      <SixthComponent :items="sixthItems" />
    </div>

    <!--Our latest insights-->
    <div
      data-animate-onscroll-offset="500"
      v-animate-onscroll="'animated fadeIn'"
      class="site-width animatedSection"
    >
      <EighthComponent :items="articles" :innerWidth="innerWidth" />
    </div>

    <!--Top Campaign in Canada-->
    <div
      data-animate-onscroll-offset="500"
      v-animate-onscroll="'animated fadeIn'"
      class="animatedSection"
    >
      <FourthComponent :ads="topAds" :period="topAdsPeriod" />
    </div>

    <!--Empowering Decision-Making-->
    <!--        <div  data-animate-onscroll-offset="500" v-animate-onscroll="'animated fadeIn'" class="site-width">-->
    <!--            <FifthComponent/>-->
    <!--        </div>-->

    <!--Advancing Technology for Advertising Intelligence-->
    <div
      data-animate-onscroll-offset="500"
      v-animate-onscroll="'animated fadeIn'"
      class="site-width animatedSection"
    >
      <ThirdComponent
        :radioItems="topRadioItems"
        :tvItems="topTvItems"
        :period="topCompaniesPeriod"
        :innerWidth="innerWidth"
      />
    </div>

    <!--New Innovations-->
    <div
      data-animate-onscroll-offset="500"
      class="animatedSection"
      v-animate-onscroll="'animated fadeIn'"
    >
      <NinthComponent :innerWidth="innerWidth" />
    </div>

    <!--Welcome to aurbit.-->
    <div
      data-animate-onscroll-offset="500"
      v-animate-onscroll="'animated fadeIn'"
      class="animatedSection"
    >
      <TenthComponent :innerWidth="innerWidth" />
    </div>

    <!--Using Adease to stay ahead-->
    <SeventhComponent :innerWidth="innerWidth" />

    <!--Contact Us-->
    <div
      data-animate-onscroll-offset="500"
      v-animate-onscroll="'animated fadeIn'"
      class="animatedSection"
    >
      <ContactUsComponent />
    </div>
  </AppLayout>
</template>

<script>
import AppLayout from "@/Layouts/AppLayout";
import FirstComponent from "@/components/homePage/FirstComponent.vue";
import SecondComponent from "@/components/homePage/SecondComponent.vue";
import ThirdComponent from "@/components/homePage/ThirdComponent.vue";
import FourthComponent from "@/components/homePage/FourthComponent.vue";
// import FifthComponent from "@/components/homePage/FifthComponent.vue";
import SixthComponent from "@/components/homePage/SixthComponent.vue";
import SeventhComponent from "@/components/homePage/SeventhComponent.vue";
import NinthComponent from "@/components/homePage/NinthComponent.vue";
import TenthComponent from "@/components/homePage/TenthComponent.vue";
import EighthComponent from "@/components/homePage/EighthComponent.vue";
// import {Intersect} from 'vue-observe-visibility';
import TrackersSectionComponent from "@/components/homePage/TrackersSectionComponent.vue";
import PlanBetterComponent from "@/components/homePage/PlanBetterComponent.vue";
import ContactUsComponent from "@/components/homePage/ContactUsComponent.vue";

export default {
  name: "HomeScreen",
  components: {
    ContactUsComponent,
    PlanBetterComponent,
    TrackersSectionComponent,
    EighthComponent,
    TenthComponent,
    NinthComponent,
    SeventhComponent,
    SixthComponent,
    // FifthComponent,
    FourthComponent,
    ThirdComponent,
    SecondComponent,
    FirstComponent,
    AppLayout,
  },
  directives: {
    // Intersect,
  },
  metaInfo() {
    return {
      title: this.seo.title,
      meta: [
        { name: "description", content: this.seo.description },
        { property: "og:title", content: this.seo.title },
        { property: "og:description", content: this.seo.description },
        { name: "twitter:title", content: this.seo.title },
        { name: "twitter:description", content: this.seo.description },
        { name: "keywords", content: this.seo.keywords },

        // Add more social meta tags as needed
      ],
    };
  },
  data() {
    return {
      seo: {
        title: "Home - Adease Media Intelligence",
        description:
          "Adease Media Intelligence is a leading provider of advertising intelligence solutions. Our innovative technology empowers businesses to make informed decisions and stay ahead of the competition.",
        keywords:
          "advertising intelligence, media intelligence, advertising analytics, media analytics, advertising insights, media insights, competitive intelligence, competitive analysis, advertising data, media data, advertising technology, media technology",
      },
      innerWidth: window.innerWidth,
      topCompaniesPeriod: null,
      topRadioItems: [],
      topTvItems: [],

      sixthItems: [
        {
          title: "Integrator",
          text: "<div>Integrate your data to measure marketing attribution and ROI against such key performance indicators (KPI's) as competitive data, sales information, media ratings, brand health metrics and more</div>",
          image: "/img/ourSolutionsPage/our-solutions-page-2.jpg",
          url: "/integrator",
        },
        {
          title: "Competitive Data",
          text: "<div>Know your competitors better than they know themselves. Our competitive insights dig deep, revealing more about their strategies than ever before. Get the edge you need to outperform your competitors.</div>",
          image: "/img/ourSolutionsPage/our-solutions-page-3.jpg",
          url: "/competitive-data",
        },
        {
          title: "Creative Services",
          text: "<div>Receive immediate updates on the latest advertising campaigns and explore our vast library of millions of ads to gain insights into your competitors’ messaging. Tap into a rich source of creative inspiration, ideal for agencies looking to spark new ideas.</div>",
          image: "/img/ourSolutionsPage/our-solutions-page-4.jpg",
          url: "/creative-services",
        },
        {
          title: "Competitive Pricing",
          text: "<div>Get real-time competitor pricing insights on flyers and e-commerce to adjust your strategy quickly and maintain a competitive edge. Our analysis helps you make informed decisions, ensuring your pricing is always attractive and competitive.</div>",
          image: "/img/ourSolutionsPage/our-solutions-page-5.jpg",
          url: "/competitive-pricing",
        },
        {
          title: "Ad Verify",
          text: "<div>Ad Verify offers enhanced transparency, enabling clients to verify ad placements against purchased schedules. It fosters trust and accountability, bridging the gap between expectations and actual ad performance.</div>",
          image: "/img/ourSolutionsPage/our-solutions-page-6.jpg",
          url: "/verification",
        },
        {
          title: "Prospector",
          text: "<div>Uncover new business leads by exploring who advertises what, when, and where. Gain insights into market trends and competitor actions, helping you identify opportunities for growth and collaboration.</div>",
          image: "/img/ourSolutionsPage/our-solutions-page-7.jpg",
          url: "/prospector",
        },
      ],
      topAdsPeriod: null,
      topAds: [],
      secondItems: [],
      articles: [],
      isVisibleSecondComponent: false,
    };
  },
  methods: {
    onScroll() {
      const element = this.$refs.secondComponent;
      const elementPosition = element.getBoundingClientRect();
      const windowInnerHeight = window.innerHeight;

      if (
        elementPosition.top < windowInnerHeight &&
        elementPosition.bottom >= 0
      ) {
        this.isVisibleSecondComponent = true;
      }
    },
    handleResize() {
      this.innerWidth = window.innerWidth;
    },
    scheduleDemo() {
      this.$router.push("/start-free-trial");
    },
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    window.addEventListener("scroll", this.onScroll);
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
    window.removeEventListener("scroll", this.onScroll);
  },
  mounted() {
    this.onScroll();

    this.$store.dispatch("Flags/changeMenuFlag", false);

    const statsBaseUrl = "https://media.adeasemediaresearch.com";

    fetch(statsBaseUrl + "/index/top-ten-items")
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        this.topRadioItems = res.radio.items;
        this.topTvItems = res.tv.items;
        this.topCompaniesPeriod = res.tv.startDate + " - " + res.tv.endDate;
      });

    fetch(statsBaseUrl + "/index/top-ads")
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        this.topAds = res.ads;
        this.topAdsPeriod = res.ads[0].startDate + " - " + res.ads[0].endDate;
      });

    fetch(statsBaseUrl + "/index/top-stats")
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        this.secondItems = res.stats;
      });

    // Switch to media for now
    fetch(statsBaseUrl + "/index/articles")
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        this.articles = res.articles;
      });
  },
};
</script>

<style>
.schedule-demo-animating-button {
  margin-top: 150px;
}
</style>
