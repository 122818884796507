<template>
  <div class="menu_overlay">
    <div class="header d-flex justify-content-between align-items-center">
      <router-link to="/" class="logo-img">
        <img
          v-if="!$store.getters['Flags/getMenuFlag']"
          src="/img/logo.png"
          alt=""
        />
        <img v-else src="/img/logo-black.png" alt="" />
      </router-link>
    </div>

    <div class="main-menu-block">
      <div class="main-menu-block__item">
        <router-link to="/company"> Company </router-link>
      </div>
      <div class="main-menu-block__item main-menu-block__item-div">
        <div @click="flagDropdown = !flagDropdown">Solutions</div>

        <div
          v-show="flagDropdown"
          ref="solutionsDropdown"
          class="solutions-dropdown-burger"
        >
          <router-link to="/integrator"> Integrator </router-link>

          <router-link to="/verification"> Verification </router-link>

          <router-link to="/competitive-data"> Competitive Data </router-link>

          <router-link to="/creative-services"> Creative Services </router-link>

          <router-link to="/competitive-pricing">
            Competitive Pricing
          </router-link>

          <router-link to="/prospector"> Prospector </router-link>

          <a href="https://aurbit.ai" target="_blank"> Aurbit </a>

          <a href="https://spotliteai.com" target="_blank"> Spotlite </a>

          <router-link to="/case-studies"> Case Studies </router-link>
        </div>
      </div>
      <div class="main-menu-block__item main-menu-block__item-div">
        <div @click="scrollTo('our-latest-insights')">Latest Insights</div>
      </div>
      <div class="main-menu-block__item">
        <router-link to="/contact"> Contact </router-link>
      </div>

      <AnimatingButton
        text="Login"
        class="login-animating-button"
        :class="
          $store.getters['Flags/getMenuFlag']
            ? 'active-menu-animating-button'
            : ''
        "
        @click="openModal"
      />

      <LoginComponent />
    </div>
  </div>
</template>

<script>
import LoginComponent from "@/components/modal/LoginComponent.vue";
import AnimatingButton from "@/components/homePage/AnimatingButton.vue";

export default {
  name: "BurgerMenuComponent",
  components: { AnimatingButton, LoginComponent },
  data() {
    return {
      url: this.$route.name,
      flagDropdown: false,
    };
  },
  methods: {
    openModal() {
      this.$bvModal.show("login");
    },

    closeMenu() {
      this.flagDropdown = false;
      const checkbox = document.getElementById("myInput");
      checkbox["checked"] = false;
      this.$store.dispatch("Flags/changeMenuFlag", false);
    },

    scrollTo(id) {
      this.closeMenu();

      const targetElement = document.getElementById(id);

      if (targetElement) {
        targetElement.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }
    },
  },

  mounted() {
    // console.log('this', this.$route.name);
  },
};
</script>

<style>
.menu_overlay .login-animating-button {
  align-items: flex-start;
}

.menu_overlay .animating-button {
  width: 343px;
}

.main-menu-block__item {
  transition: 0.2s;
  margin-bottom: 54px;
}

.main-menu-block {
  display: flex;
  flex-direction: column;
  margin: 170px auto 80px;
  max-width: 1520px;
  text-align: left;
}

.main-menu-block a,
.main-menu-block__item-div {
  transition: 0.2s;
  color: rgb(35, 36, 41);
  font-size: 60px;
  font-weight: 500;
  line-height: 70px;
  cursor: pointer;
}

.main-menu-block__item-div div:hover {
  display: inline-block;
}

.main-menu-block .main-menu-block__item-div div {
  border-bottom: 2px solid transparent;
}

.main-menu-block a:hover,
.main-menu-block .main-menu-block__item-div div:hover,
.main-menu-block .router-link-exact-active {
  color: rgb(35, 36, 41) !important;
  text-decoration: none;
  border-bottom: 2px solid rgb(35, 36, 41);
}

.main-menu-block a:not(:last-child) {
  margin-bottom: 20px;
}

.solutions-dropdown-burger {
  margin: 30px 80px 0;
}

.solutions-dropdown-burger:hover {
  border-bottom: none !important;
}

.solutions-dropdown-burger a {
  font-size: 35px !important;
  display: list-item !important;
  margin: 0 !important;
}

.solutions-dropdown-burger a {
  border-bottom: 2px solid transparent;
}

@media (max-width: 1300px) {
  .main-menu-block {
    padding: 0 62px;
    margin: 107px auto 80px;
  }
}

@media (max-width: 450px) {
  .menu_overlay .animating-button {
    width: 100%;
  }
}
</style>
