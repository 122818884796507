import VueRouter from 'vue-router';

Vue.use(VueRouter);
import Vue from "vue";

import HomeScreen from "@/Pages/Screens/HomeScreen";
import ScheduleDemo from "@/Pages/Screens/ScheduleDemo";
import ContactScreen from "@/Pages/Screens/ContactScreen";
import AboutScreen from "@/Pages/Screens/AboutScreen";
import OurSolutionsScreen from "@/Pages/Screens/OurSolutionsScreen";
import ProspectorScreen from "@/Pages/Screens/ProspectorScreen.vue";
import CaseStudyScreen from "@/Pages/Screens/CaseStudyScreen.vue";
import CompetitiveDataScreen from "@/Pages/Screens/CompetitiveDataScreen.vue";
import CompetitivePricingScreen from "@/Pages/Screens/CompetitivePricingScreen.vue";
import CreativeServicesScreen from "@/Pages/Screens/CreativeServicesScreen.vue";
import IntegratorScreen from "@/Pages/Screens/IntegratorScreen.vue";
import VerificationScreen from "@/Pages/Screens/VerificationScreen.vue";
import CaseStudiesScreen from "@/Pages/Screens/CaseStudiesScreen.vue";
import IntegratorCaseStudyScreen from "@/Pages/Screens/IntegratorCaseStudyScreen.vue";
import VerificationCaseStudyScreen from "@/Pages/Screens/VerificationCaseStudyScreen.vue";
import CompetitiveBrandsCaseStudyScreen from "@/Pages/Screens/CompetitiveBrandsCaseStudyScreen.vue";
import CreativeCaseStudyScreen from "@/Pages/Screens/CreativeCaseStudyScreen.vue";
import CompetitivePricingCaseStudyScreen from "@/Pages/Screens/CompetitivePricingCaseStudyScreen.vue";
// import ProductsScreen from "@/Pages/Screens/ProductsScreen";


let router = new VueRouter({
    mode: 'history',

    routes: [
        {
            path: '/',
            name: 'HomeScreen',
            component: HomeScreen,
        },
        {
            path: '/contact',
            name: 'ContactScreen',
            component: ContactScreen,
        },
        {
            path: '/company',
            name: 'AboutScreen',
            component: AboutScreen,
        },
        {
            path: '/solutions',
            name: 'OurSolutionsScreen',
            component: OurSolutionsScreen,
        },
        {
            path: '/prospector',
            name: 'ProspectorScreen',
            component: ProspectorScreen,
        },
        {
            path: '/prospector-case-study',
            name: 'CaseStudyScreen',
            component: CaseStudyScreen,
        },
        {
            path: '/integrator',
            name: 'IntegratorScreen',
            component: IntegratorScreen,
        },
        {
            path: '/integrator-case-study',
            name: 'IntegratorCaseStudyScreen',
            component: IntegratorCaseStudyScreen,
        },
        {
            path: '/verification',
            name: 'VerificationScreen',
            component: VerificationScreen,
        },
        {
            path: '/verification-case-study',
            name: 'VerificationCaseStudyScreen',
            component: VerificationCaseStudyScreen,
        },
        {
            path: '/competitive-data',
            name: 'CompetitiveDataScreen',
            component: CompetitiveDataScreen,
        },
        {
            path: '/competitive-data-case-study',
            name: 'CompetitiveBrandsCaseStudyScreen',
            component: CompetitiveBrandsCaseStudyScreen,
        },
        {
            path: '/creative-services',
            name: 'CreativeServicesScreen',
            component: CreativeServicesScreen,
        },
        {
            path: '/creative-services-case-study',
            name: 'CreativeCaseStudyScreen',
            component: CreativeCaseStudyScreen,
        },
        {
            path: '/competitive-pricing',
            name: 'CompetitivePricingScreen',
            component: CompetitivePricingScreen,
        },
        {
            path: '/competitive-pricing-case-study',
            name: 'CompetitivePricingCaseStudyScreen',
            component: CompetitivePricingCaseStudyScreen,
        },
        {
            path: '/case-studies',
            name: 'CaseStudiesScreen',
            component: CaseStudiesScreen,
        },
        // {
        //     path: '/products',
        //     name: 'ProductsScreen',
        //     component: ProductsScreen,
        // },
        {
            path: '/start-free-trial',
            name: 'ScheduleDemo',
            component: ScheduleDemo,
        },
        {
            path: '*',
            redirect() {
                return '/';
            }
        }
    ]
});

export default router;
