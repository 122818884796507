<template>
    <div>
        <div class="case-studies-item-component">
            <img :src="img" :alt="title">
            <div class="case-studies-item-component-block">
                <div class="case-studies-item-component-block-text">
                    <div class="case-studies-item-component-block-bages">
                        <div class="case-studies-item-component-block__bage">
                            <span>CASE STUDY
                        </span></div>                        <div class="case-studies-item-component-block__date2">
                            {{ date }}
                        </div>
                    </div>

                    <a :href="url" class="case-studies-item-component-block__title">
                        {{ title }}
                    </a>
                    <div class="case-studies-item-component-block__text">
                        <p>{{ text }}
                    </p></div>
                </div>
                <div class="case-studies-item-component-block__date">
                    {{ date }}
                </div>
            </div>
        </div>
        <div class="case-studies-item-component-block__line"></div>
    </div>

</template>

<script>
export default {
    name: "CaseStudiesItemComponent",
    props: ['title', 'text', 'date', 'img', 'url'],
    data() {
        return {}
    }
}
</script>

<style scoped>
.case-studies-item-component {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
    gap: 24px;
}

.case-studies-item-component img {
    width: 450px;
    height: 289px;
    object-fit: cover;
    object-position: center;
}

.case-studies-item-component-block-text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
}

.case-studies-item-component-block {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
}

.case-studies-item-component-block__bage {
    color: #202E49;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: -0.4px;
    border-radius: 61px;
    background: rgba(174, 198, 244, 0.40);
    padding: 0 6px;
}

.case-studies-item-component-block__title {
    color: #202E49;
    font-size: 40px;
    font-style: normal;
    font-weight: 500;
    line-height: 52px;
    letter-spacing: -2px;
    margin-bottom: 16px;
    text-decoration: none;
}

.case-studies-item-component-block__title:hover {
    text-decoration: none;
    color: #202E49;
}

.case-studies-item-component-block__text {
    color: var(--gray, #828282);
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: -0.4px;
    margin-bottom: 36px;
}

.case-studies-item-component-block__date {
    color: #202E49;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: -0.4px;
}

.case-studies-item-component-block__line {
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    margin: 52px 0;
}

.case-studies-item-component-block__date2{
    color: #202E49;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: -0.12px;
    display: none;
}

.case-studies-item-component-block-bages{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
    margin-bottom: 16px;
}

@media screen and (max-width: 1300px) {
    .case-studies-item-component-block__bage {
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: -0.16px;
    }

    .case-studies-item-component-block__title {
        font-size: 28px;
        font-style: normal;
        font-weight: 500;
        line-height: 36px;
        letter-spacing: -0.56px;
    }
}

@media screen and (max-width: 992px) {
    .case-studies-item-component-block__title {
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: 32px;
        letter-spacing: -0.72px;
    }

    .case-studies-item-component-block__date {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: -0.16px;
    }

    .case-studies-item-component-block__text {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: -0.16px;
    }

    .case-studies-item-component img {
        width: 370px;
        height: 220px;
    }

    .case-studies-item-component-block__line {
        margin: 40px 0;
    }
}

@media screen and (max-width: 768px) {
    .case-studies-item-component img {
        width: 260px;
        height: 203.081px;
    }

    .case-studies-item-component-block__bage {
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: -0.14px;
    }

    .case-studies-item-component-block__title {
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: -0.36px;
        margin-bottom: 8px;
    }

    .case-studies-item-component-block__date {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: -0.14px;
    }

    .case-studies-item-component-block__text {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: -0.14px;
    }
}

@media screen and (max-width: 600px) {
    .case-studies-item-component {
        flex-direction: column;
    }

    .case-studies-item-component img {
        width: 100%;
        height: 203.081px;
    }

    .case-studies-item-component-block__bage {
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
        letter-spacing: -0.12px;
    }

    .case-studies-item-component-block__title {
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: 32px;
        letter-spacing: -1.2px;
    }

    .case-studies-item-component-block__date {
        display: none;
    }

    .case-studies-item-component-block__date2{
        display: block;
    }

    .case-studies-item-component-block__text {
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px;
        letter-spacing: -0.18px;
        margin-bottom: 0;
    }

    .case-studies-item-component-block__line {
        margin: 52px 0;
    }
}
</style>