<template>
  <PageLayout>
    <div class="min-h-65 site-width">
      <div class="block-padding contact-page">
        <div class="page-title">
          <h2>Contact Us</h2>
        </div>

        <b-form @submit.prevent="sendData" class="form-contact-page">
          <div class="form-contact-page-block">
            <b-form-group>
              <label> First Name </label>
              <b-form-input
                v-model="form.firstname"
                type="text"
                required
                autocomplete="off"
              />
            </b-form-group>

            <b-form-group>
              <label> Last Name </label>
              <b-form-input
                v-model="form.lastname"
                type="text"
                required
                autocomplete="off"
              />
            </b-form-group>
          </div>
          <div class="form-contact-page-block">
            <b-form-group>
              <label> Email </label>
              <b-form-input
                v-model="form.email"
                type="email"
                required
                autocomplete="off"
              />
            </b-form-group>
            <b-form-group class="empty-block"></b-form-group>
          </div>

          <div class="form-contact-page-block">
            <b-form-group>
              <label> Subject </label>
              <b-form-input
                v-model="form.subject"
                type="text"
                required
                autocomplete="off"
              />
            </b-form-group>
            <b-form-group class="empty-block"></b-form-group>
          </div>

          <div class="form-contact-page-block">
            <b-form-group>
              <label> Message </label>
              <b-form-textarea
                id="textarea"
                rows="1"
                v-model="form.message"
                required
              ></b-form-textarea>
            </b-form-group>
          </div>

          <div class="form-contact-page-block form-contact-page-white-btn">
            <button :disabled="loader" class="position-relative" type="submit">
              Send Message
              <b-icon icon="arrow-right" class="arrow-right-animating"></b-icon>
              <b-spinner
                v-if="loader"
                class="spinner-btn ml-10"
                label="..."
              ></b-spinner>
            </button>
            <div></div>
          </div>
        </b-form>
      </div>
    </div>
  </PageLayout>
</template>

<script>
import PageLayout from "@/Layouts/PageLayout.vue";
import axios from "axios";
import methods from "@/Includes/util";

export default {
  name: "ContactScreen",
  components: { PageLayout },
  metaInfo() {
    return {
      title: this?.seo?.title,
      meta: [
        { name: "description", content: this?.seo?.description },
        { property: "og:title", content: this?.seo?.title },
        { property: "og:description", content: this?.seo?.description },
        { name: "twitter:title", content: this?.seo?.title },
        { name: "twitter:description", content: this?.seo?.description },
        { name: "keywords", content: this.seo.keywords },
        // Add more social meta tags as needed
      ],
    };
  },
  data() {
    return {
      seo: {
        title: "Contact Us - Adease Media Intelligence",
        description:
          "Adease Media Intelligence is a leading provider of advertising intelligence solutions. Our innovative technology empowers businesses to make informed decisions and stay ahead of the competition.",
        keywords:
          "advertising intelligence, media intelligence, advertising analytics, media analytics, advertising insights, media insights, competitive intelligence, competitive analysis, advertising data, media data, advertising technology, media technology",
      },
      loader: false,
      form: {
        firstname: "",
        lastname: "",
        email: "",
        subject: "",
        message: "",
      },
    };
  },
  methods: {
    resetForm() {
      this.form = {
        firstname: "",
        lastname: "",
        email: "",
        subject: "",
        message: "",
      };
    },
    sendData() {
      this.loader = true;

      axios
        .post("https://media.adeasemediaresearch.com/index/contact", this.form)
        .then((res) => {
          if (res.data.status) {
            this.resetForm();
            methods.makeSuccessToast(this, res.data.message);
          } else {
            methods.makeErrorToast(this, res.data.message);
          }
          this.loader = false;
        })
        .catch((error) => {
          console.error(error);
          this.loader = false;
        });
    },
  },
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>

<style>
.form-contact-page {
  width: 100%;
}

.contact-page {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 10%;
}

.contact-page-text {
  font-size: 24px;
  margin-bottom: 16px;
}

.contact-page-text-mb {
  margin-bottom: 50px;
}

.form-contact-page .form-group {
  width: 100%;
  margin-bottom: 40px;
}

.form-contact-page .form-group label {
  color: #232429;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 25px;
}

.form-contact-page .form-group textarea {
  min-height: 61px;
  height: 61px;
  overflow: hidden;
}

.form-contact-page .form-group input,
.form-contact-page .form-group textarea {
  border: none;
  border-bottom: 1px solid #aec6f4;
  border-radius: 0;
  padding: 10px 0;
  color: #232429;
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: 42px;
}

.form-contact-page .form-group input:focus,
.form-contact-page .form-group textarea:focus {
  box-shadow: none;
}

.form-contact-page .form-group input::placeholder {
  color: #232429;
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: 42px;
}

.form-contact-page-block {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 80px;
  margin-bottom: 50px;
}

.form-contact-page-white-btn button,
.form-contact-page-white-btn div {
  width: 50%;
}

.form-contact-page-white-btn button {
  display: flex;
  padding: 16px 56px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: #232429;
  text-align: center;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.44px;
  border: 1px solid #232429;
  background: #fff;
  transition: 0.5s;
}

.form-contact-page-white-btn button:hover {
  color: #fff;
  background: #232429;
}

.empty-block {
  visibility: hidden;
}

@media (max-width: 992px) {
  .contact-page {
    flex-direction: column;
  }

  .contact-page-content,
  .form-contact-page {
    width: 100%;
  }

  .form-contact-page {
    margin-top: 50px;
  }

  .form-contact-page-white-btn button,
  .form-contact-page-white-btn div {
    width: 100%;
  }

  .form-contact-page-block {
    flex-direction: column;
    gap: 0;
  }
}
</style>
