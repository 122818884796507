import { render, staticRenderFns } from "./CaseStudiesItemComponent.vue?vue&type=template&id=75ba5881&scoped=true"
import script from "./CaseStudiesItemComponent.vue?vue&type=script&lang=js"
export * from "./CaseStudiesItemComponent.vue?vue&type=script&lang=js"
import style0 from "./CaseStudiesItemComponent.vue?vue&type=style&index=0&id=75ba5881&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "75ba5881",
  null
  
)

export default component.exports