<template>
  <AppLayout>
    <div class="prospector-screen-component">
      <div
        class="case-study-screen-component-head"
        style="background-image: url('/img/ourSolutionsPage/Case-Studies.jpg')"
      >
        <div class="case-study-screen-component-head-content">
          <div class="case-study-screen-component-head-block">
            <div class="case-studies-screen-component-head-title">
              Case Studies
            </div>
          </div>
        </div>
      </div>

      <div class="site-width case-studies-items">
        <CaseStudiesItemComponent
          v-for="(item, key) in items"
          :key="key"
          :title="item.title"
          :text="item.text"
          :date="item.date"
          :img="item.img"
          :url="item.url"
        />
      </div>
    </div>
  </AppLayout>
</template>

<script>
import AppLayout from "@/Layouts/AppLayout.vue";
import CaseStudiesItemComponent from "@/components/CaseStudiesItemComponent.vue";

export default {
  name: "CaseStudiesScreen",
  components: { CaseStudiesItemComponent, AppLayout },
  metaInfo() {
    return {
      title: this?.seo?.title,
      meta: [
        { name: "description", content: this?.seo?.description },
        { property: "og:title", content: this?.seo?.title },
        { property: "og:description", content: this?.seo?.description },
        { name: "twitter:title", content: this?.seo?.title },
        { name: "twitter:description", content: this?.seo?.description },
       { name: "keywords", content: this.seo.keywords },
 // Add more social meta tags as needed
      ],
    };
  },
  data() {
    return {
      seo: {
        title: "Case Studies - Adease Media Intelligence",
        description:
          "Adease Media Intelligence is a leading provider of advertising intelligence solutions. Our innovative technology empowers businesses to make informed decisions and stay ahead of the competition.",
        keywords:
          "advertising intelligence, media intelligence, advertising analytics, media analytics, advertising insights, media insights, competitive intelligence, competitive analysis, advertising data, media data, advertising technology, media technology",
      },
      items: [
        {
          img: "/img/eighth3?.jpg",
          title: "Southeast Asia: CPG Marketing Mix Modeling Meta Analysis",
          text: "Introduction Measure the full funnel impact of TikTok advertising TikTok, a global entertainment platform, captures the...",
          date: "18-03-2024",
          url: "/",
        },
        {
          img: "/img/eighth2.jpg",
          title: "Evolving the measurement of sports events",
          text: "Nielsen worked with World Athletics on new measurements of success for hosting major sports events.",
          date: "18-03-2024",
          url: "/",
        },
        {
          img: "/img/eighth1.jpg",
          title: "Southeast Asia: CPG Marketing Mix Modeling Meta Analysis",
          text: "Introduction Measure the full funnel impact of TikTok advertising TikTok, a global entertainment platform, captures the...",
          date: "18-03-2024",
          url: "/",
        },
        {
          img: "/img/eighth2.jpg",
          title: "Evolving the measurement of sports events",
          text: "Nielsen worked with World Athletics on new measurements of success for hosting major sports events.",
          date: "18-03-2024",
          url: "/",
        },
        {
          img: "/img/eighth3.jpg",
          title: "Southeast Asia: CPG Marketing Mix Modeling Meta Analysis",
          text: "Introduction Measure the full funnel impact of TikTok advertising TikTok, a global entertainment platform, captures the...",
          date: "18-03-2024",
          url: "/",
        },
        {
          img: "/img/eighth1.jpg",
          title: "Southeast Asia: CPG Marketing Mix Modeling Meta Analysis",
          text: "Introduction Measure the full funnel impact of TikTok advertising TikTok, a global entertainment platform, captures the...",
          date: "18-03-2024",
          url: "/",
        },
      ],
    };
  },
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>

<style>
.case-studies-items {
  margin-top: 160px;
  margin-bottom: 160px;
}

.case-studies-screen-component-head-title {
  color: #fff;
  text-align: center;
  font-size: 64px;
  font-style: normal;
  font-weight: 500;
  line-height: 88px;
  letter-spacing: -2.56px;
}

.prospector-screen-component-item img {
  padding-top: 14px;
}

@media screen and (max-width: 1300px) {
  .case-studies-screen-component-head-title {
    font-size: 48px;
    font-style: normal;
    font-weight: 500;
    line-height: 64px;
    letter-spacing: -1.92px;
  }
}

@media screen and (max-width: 992px) {
  .case-studies-screen-component-head-title {
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: 48px;
    letter-spacing: -1.28px;
  }
}

@media screen and (max-width: 768px) {
}

@media screen and (max-width: 500px) {
}
</style>
